import React, {useState} from 'react'
import {Modal} from 'reactstrap'

import ImgMhotspot from './../assets/images/yt_thumb.png';

const styles = {
  videoWrapper: {
    display: 'flex',
    // justifyContent: 'center',
    margin: 10,
  },
  ytThumb: {
    cursor: 'pointer',
    width: '100%'
  }
}

const YoutubeEmbed = props => {
  const [open, setOpen] = useState(false)
  const toggle = () => setOpen(!open);
  return (
    <div style={styles.videoWrapper}>
   
      <img src={ImgMhotspot} alt="what-is-mhotspot" style={styles.ytThumb} onClick={()=> {
        setOpen(true)
         console.log(open)
        } }/>
      <Modal isOpen={open} toggle={toggle} centered size="lg">
   <div
        className="embed-responsive embed-responsive-16by9"
        // style={{ maxWidth: 700 }}
      >
        <iframe
          title="youtube embed iframe"
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${props.videoId}?rel=0&autoplay=1`}
          allowFullScreen
        />
      </div>
      </Modal>
    </div>
  )
}

export default YoutubeEmbed
