import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import IMGScreenshot from './../assets/images/mHotspot_7_1.png'
import YoutubeEmbed from './../components/YoutubeEmbed'

class homePage extends React.Component {
  render() {
    return (
      <Layout>
        <div>
          <h1 style={{ fontSize: 26 }}>
            Turn your windows laptop into wifi hotspot and make a virtual wifi
            router
          </h1>
          <YoutubeEmbed videoId="S8bunQpsaNo" />
          <p>
            mHotspot is a <a>completely free</a> software which converts your
            windows 7, 8 & 10 laptop into a virtual wifi router and creates a
            secure wifi hotspot
          </p>
          <p>
            You can share single internet connection(LAN, Ethernet, Data-Card,
            3G/4G, Wifi) for multiple devices like laptops, smartphones, android
            phones, PDAs, Ipads etc. Play{' '}
            <Link to="/uses">Multiplayer gaming</Link> using mHotspot
          </p>
          <p>
            No router or external hardware is required apart from your existing
            one.
          </p>
        </div>

        <br />

        <div>
          <h2 style={{ fontSize: 26 }}>Screenshots</h2>
          <img src={IMGScreenshot} alt="mhotspot ui" />
        </div>

        <br />

        <div>
          <h2 style={{ fontSize: 26 }}>Features</h2>
          <ul>
            <li>Connect upto 10 devices to the hotspot</li>
            <li>You can set your own hotspot name without any restrictions</li>
            <li>Application size is 400KB only</li>
            <li>
              Share any type of Internet Connection(LAN, Ethernet,3G/4G,Wifi
              etc)
            </li>
            <li>
              Android phones, ipads, PDAs, tablet-pcs and other devices can
              access
            </li>
            <li>
              See the details of the connected device(Name, Ip Address, Mac
              Address)
            </li>
            <li>
              See the network usage(Upload and Download Speed, Transfer Rates)
            </li>
            <li>
              Secures your wireless hotspot with WPA2 PSK password security
            </li>
            <li>Set max. number of devices that can be connected</li>
            <li>Extends your Wifi range(Acts as a repeater)</li>
            <li>In-app internet selection</li>
            <li>Works with Windows 7, 8, & 10</li>
          </ul>
        </div>
        <br />
        <div>
          <h2 style={{ fontSize: 26 }}>Watch the Video Tutorial</h2>
          <YoutubeEmbed videoId="gI5iVgpk8vM" />
        </div>
      </Layout>
    )
  }
}

export default homePage
